.cursor-pointer {
  cursor: pointer;
}

.alert {
  cursor: pointer;
}

.alert:empty {
  display: none;
}
.post-img-preview {
  height: 60px;
  width: 60px;
  border-radius: 14px;
  background-size: cover;
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.3rem;
}
.post-img-preview::after {
  content: "";
  height: 30px;
  width: 30px;
  display: block;
  border-radius: 50%;
  border: 3px solid #d3d3d3;
  border-bottom-color: transparent;

  animation: 0.75s linear infinite spinner-border;
}
@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
.img-gravatar {
  height: 40px;
  width: 40px;
  display: inline-flex;
  border-radius: 50%;
  background-color: #8a14142b;
  align-items: center;
  justify-content: center;
  color: #8a1414;
  cursor: default;
  user-select: none;
}

.custom-textarea:empty:after {
  content: attr(placeholder);
  pointer-events: none;
}

.custom-textarea {
  min-height: 100px;
  height: auto !important;
  outline: 0;
}
fieldset:has(:invalid) .next-action {
  opacity: 0.7;
  pointer-events: none !important;
}
.tab-pen:has(.team-item):has(.team-item[style="display: none;"])::after {
  content: "No Content";
}
body:has(.modal-custom) {
  overflow: hidden;
}
.modal-custom {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgb(0 0 0/20%);
  z-index: 999;

  display: flex;
  justify-content: center;
  align-items: center;
}
.fake-checkbox {
  display: inline-block;
  height: 17px;
  position: relative;
  width: 17px;
  border: 1px solid rgb(0 0 0/70%);
  border-radius: 3px;
}
.fake-checkbox:hover {
  background-color: rgb(0 0 0/10%);
}
.fake-checkbox.YES {
  background-color: green;
}
.fake-checkbox.YES::after {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  content: "\f00c";
  color: white;
  font-size: 10px;
  font-weight: bold;
  font-family: "Font Awesome 5 Free";
}
.fake-checkbox.NO {
  background-color: crimson;
}
.fake-checkbox.NO::after {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  content: "\f00d";
  color: white;
  font-size: 10px;
  font-weight: bold;
  font-family: "Font Awesome 5 Free";
}
.fake-checkbox.MAYBE {
  background-color: rgb(247, 143, 74);
}

.fake-checkbox.MAYBE::after {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  content: "?";
  color: white;
  font-size: 12px;
  font-weight: bold;
  font-family: "Font Awesome 5 Free";
}
.modal-custom .modal-body {
  width: 100%;
  max-width: 380px;
  padding: 12px;
  background-color: #fff;
}
.custom-textarea img {
  height: 60px;
  width: 60px;
  display: inline-flex;
  margin-right: 0.3rem;
  object-fit: cover;
  border-radius: 15px;
  position: relative;
  vertical-align: bottom;
}
[data-badge] {
  position: relative;
}

[data-badge]::after {
  content: attr(data-badge);
  font-family: sans-serif;
  font-size: 13px;
  font-weight: 300;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(100%, -50%);
  color: #8a1414;
}
.input-with-icon {
  position: relative;
}

.input-with-icon input {
  padding-right: 40px;
}

.input-with-icon::before {
  content: "";
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  background: url("../public/assets/frontend/images/marker-blue.png") no-repeat
    center center;
  background-size: contain;
}

.summary-box {
  border: 1px solid #ccc;
  /* box-shadow: 2px  #8a1414; */
  padding: 8px;
  background-color: #f9f9f9;
  border-radius: 8px;
  margin: 5px;
  display: flex;
  justify-content: space-between;
}

.summary-section {
  flex: 1;
  text-align: center;
  margin: 0 5px;
}

.section-title {
  font-weight: bold;
}

.document-name {
  margin: 10px;
  font-size: 15px;
}
.uploaded-document {
  margin-left: 5px;
}
.card-items {
  display: flex;
  justify-content: space-between;
}
.options-button {
  font-size: 16px;
  position: absolute;
  top: 1px;
  right: 1px;
  z-index: 1;
  cursor: pointer;
  padding: 1px;
  color: black(0, 0, 0, 0.808);
}

.card-features {
  position: relative;
  width: 330px;
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.card-features img {
  width: 100%;
  height: auto;
  display: block;
}

.card-content-features {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  text-align: center;
}

.logo-features img {
  width: 60px;
  height: 60px;
}

.header-features {
  font-size: 18px;
  /* font-weight: bold; */
  margin: 10px 0;
}

.text-features {
  font-size: 14px;
}
.card-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  margin: 0 auto;
  padding: 12px auto;
}
.blue-icon {
  font-size: 30px;

  color: #cc4b4c;
}
.excel-icon {
  font-size: 30px;
  color: #169154;
}
.word-icon {
  font-size: 30px;
  color: rgb(95, 95, 226);
}
.option-icon {
  font-size: 30px;
}
.back-btn {
  margin-right: auto;
}

.ul {
  list-style: none;
  padding: 0;
}

.li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
  border: 1px solid #ddd;
  padding: 8px;
  background-color: #fff;
  border-radius: 4px;
}

.li strong {
  font-weight: bold;
  font-size: 1rem;
}

.btn-marooon {
  border-radius: 2px;
  cursor: pointer;
}

.li span {
  display: flex;
  align-items: center;
}

.li .btn-marooon {
  margin-left: 10px;
}
.li:hover {
  background-color: rgb(246, 236, 236);
}
.list-group-item:hover {
  background-color: rgb(246, 236, 236);
}
/* Default button styles */
.btn-group.toggle-button {
  margin-bottom: 10px;
  color: #f9f9f9;
}

.btn.btn-toggle {
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.grid-btn {
  background-color: #ccc;
  color: #333;
}

.grid-btn.active {
  background-color: #7d0000;
}

.list-button {
  background-color: #ccc;
  color: #333;
}

.list-button.active {
  background-color: #7d0000;
}

.btn.btn-toggle:hover {
  filter: brightness(1.1);
  color: #333;
}

.btn.btn-toggle.active:hover {
  filter: brightness(0.9);
  color: #ccc;
}
.card-options{
  background-color: rgb(246, 236, 236);
}
.card-options:hover{
  background-color: rgb(233, 196, 196);
}
/* Example CSS for TimePicker */
.react-time-picker__wrapper {
  display: inline-block;
}

.react-time-picker__inputGroup {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 150px; /* Adjust width as needed */
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 5px;
}

.react-time-picker__inputGroup__input {
  border: none;
  outline: none;
  font-size: 14px;
  padding: 5px;
  width: 60px;
}
.card-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.card-features {
  flex: 0 0 calc(33.33% - 25px); 
  margin: 30px;
  margin-right: 45px;
}

@media (max-width: 768px) {
  .card-features {
    flex: 0 0 calc(50% - 20px);
  }
}
.sg-list{
    position: absolute;
    width: 100%;
    background-color: white;
    list-style: none;
    display: none;
    padding: 0;
    cursor: pointer;
    z-index: 100;
}
.sg-list div{
    padding: 8px 10px;
    transition: 0.1s;

}
.sg-list div:hover{
    background-color: rgb(0 0 0 / 10%);
}
input:focus + .sg-list, .sg-list:hover{
    display: block;
}